import './App.css';

function App() {
  return (
    <div style={{ height: '100vh', width: '100%', overflow:'hidden' }}>
      <iframe 
        src="https://app.flutterflow.io/dashboard" 
        style={{ border: 'none', width: '100%', height: '100%' }}
        title="FF-Armyali"
      />
    </div>
  );
}

export default App;
